import valdoise from "/public/images/blog/valdoise.webp";
import poomsaeFormeTradi from "/public/images/blog/poomsae-forme-tradi.webp";
import preparationMentale from "/public/images/blog/preparation-mentale-competition.webp";
import bienfaitsTaekwondo from "/public/images/blog/bienfaits-taekwondo-enfants.webp";
import label2etoiles from "/public/images/blog/label-2-etoiles.webp";
import label3etoiles from "/public/images/blog/label-3-etoiles.webp";
import wilfried6emedan from "/public/images/blog/wilfried-6eme-dan.webp";
import jeff3emedan from "/public/images/blog/jeff-3eme-dan.webp";

import { StaticImageData } from "next/image";

type ContentSegment = {
  text: string;
  isBold?: boolean;
};

type ContentItem = {
  type: "paragraph" | "list";
  segments: ContentSegment[];
  items?: string[];
};

export type Blog = {
  id: string;
  title: string;
  description: string;
  date: string;
  imageBanner: StaticImageData;
  content: ContentItem[];
};

export const blogsData: Blog[] = [
  {
    id: "diplomes-au-mudo-club",
    title: "Pluie de diplômes au Mudo Club Argenteuil !",
    description:
      "Le Mudo Club célèbre une année exceptionnelle avec des réussites marquantes : Hamsa, Franck et Jean-François ont obtenu des diplômes prestigieux en Taekwondo.",
    date: "2021-07-25T00:00:00Z",
    imageBanner: jeff3emedan,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette année a été marquée par une véritable ",
          },
          {
            text: "pluie de diplômes",
            isBold: true,
          },
          {
            text: " au Mudo Club Argenteuil, avec des réussites qui témoignent du talent et de l’engagement de nos membres.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "En février, Hamsa a brillamment décroché son ",
          },
          {
            text: "DIF (Diplôme d'Instructeur Fédéral)",
            isBold: true,
          },
          {
            text: " et son ",
          },
          {
            text: "CQP (Certificat de Qualification Professionnelle)",
            isBold: true,
          },
          {
            text: " option Taekwondo. Une double réussite qui reflète son travail acharné et sa passion pour la discipline. Bravo Hamsa !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "En juillet, Franck a obtenu son ",
          },
          {
            text: "DEJEPS (Diplôme d'État Jeunesse, Éducation Populaire et Sport)",
            isBold: true,
          },
          {
            text: " option Taekwondo, un diplôme reconnu par l’État qui illustre son expertise et son dévouement à l’enseignement du Taekwondo. Félicitations Franck !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Et enfin, Jean-François, notre président, a également brillé cette année en obtenant son ",
          },
          {
            text: "3ème Dan",
            isBold: true,
          },
          {
            text: ". Une étape importante qui témoigne de son engagement envers notre club et notre art martial. Bravo Jean-François !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Félicitations à nos diplômés pour ces accomplissements exceptionnels. Ces succès sont une source d’inspiration pour l’ensemble du club. ",
          },
          {
            text: "Ensemble, continuons à viser l’excellence !",
            isBold: true,
          },
        ],
      },
    ],
  },
  {
    id: "label-2-etoiles",
    title: "Le Mudo Club décroche le label qualité 2 étoiles !",
    description:
      "Le Mudo Club est honoré d'avoir obtenu le label qualité 2 étoiles, une reconnaissance officielle de la FFTDA pour notre engagement envers l'excellence.",
    date: "2019-07-26T00:00:00Z",
    imageBanner: label2etoiles,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Nous sommes ravis d'annoncer que le Mudo Club a obtenu le ",
          },
          {
            text: "label qualité 2 étoiles",
            isBold: true,
          },
          {
            text: " décerné par la Fédération Française de Taekwondo et des Disciplines Associées (FFTDA). Cette distinction valorise nos efforts constants pour offrir un environnement de pratique de qualité à tous nos adhérents.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Tous les deux ans, la FFTDA organise une campagne rigoureuse de labellisation, permettant aux clubs candidats de démontrer leur engagement envers l'excellence. Ce label constitue un repère important pour les mairies et le public, attestant de la qualité des infrastructures, de l’encadrement et des activités proposées.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Le label 2 étoiles est attribué pour une durée de deux saisons et récompense les clubs qui se distinguent par leur professionnalisme, leur pédagogie et leur contribution au développement du Taekwondo.",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Pourquoi ce label est important :" }],
        items: [
          "Reconnaissance officielle de la FFTDA",
          "Gage de confiance pour les pratiquants et leurs familles",
          "Valorisation des efforts du club en matière d'encadrement et d'infrastructures",
          "Atout pour collaborer avec les mairies et institutions locales",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette distinction nous motive à poursuivre notre engagement pour le développement du Taekwondo et à continuer d'améliorer nos services. Merci à tous ceux qui ont contribué à cet accomplissement !",
          },
        ],
      },
    ],
  },

  {
    id: "6eme-dan-maitre-wilfried-avegnon",
    title: "6ème Dan pour Maître Wilfried Avegnon",
    description:
      "Le 27 mai 2018, Maître Wilfried Avegnon a obtenu sa 6ème Dan, terminant majeur de promotion. Une fierté immense pour tout le Mudo Club Argenteuil.",
    date: "2018-05-27T00:00:00Z",
    imageBanner: wilfried6emedan,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le dimanche 27 mai 2018 a marqué une étape importante pour le Mudo Club Argenteuil. ",
          },
          {
            text: "Maître Wilfried Avegnon",
            isBold: true,
          },
          {
            text: " a obtenu sa 6ème Dan avec brio, une reconnaissance qui vient récompenser des années de dévouement et de maîtrise du Taekwondo.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce succès est d’autant plus impressionnant qu’il a terminé ",
          },
          {
            text: "majeur de promotion",
            isBold: true,
          },
          {
            text: ", témoignant de son excellence et de son engagement dans l’art martial. Cette distinction reflète non seulement son parcours remarquable, mais également les valeurs et l’excellence du Mudo Club.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Tous les membres du Mudo Club Argenteuil tiennent à exprimer leur immense fierté et leurs chaleureuses félicitations à Maître Wilfried Avegnon. Cet accomplissement inspire et motive l’ensemble du club à viser l’excellence.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Un grand bravo pour cet exploit exceptionnel ! Vive le Taekwondo !",
            isBold: true,
          },
        ],
      },
    ],
  },

  {
    id: "nouveau-president-cdt95",
    title:
      "Le Comité Départemental de Taekwondo du Val d'Oise a un nouveau président !",
    description:
      "Maître Wilfried Avegnon a été élu président du CDT95. Une nouvelle ère commence pour le Taekwondo dans le Val d'Oise, placée sous le signe de l'unité et du développement.",
    date: "2017-05-11T00:00:00Z",
    imageBanner: valdoise,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Nous avons l'honneur et la joie de féliciter ",
          },
          {
            text: "Maître Wilfried Avegnon",
            isBold: true,
          },
          {
            text: ", nouvellement élu président du Comité Départemental de Taekwondo du Val d'Oise (CDT95). Cette nomination marque le début d'une nouvelle page pour notre discipline dans le département.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Sous la présidence de Maître Wilfried, l'objectif principal est clair : mettre fin à l'individualisme entre clubs et renforcer la coopération entre tous les acteurs du Taekwondo Val d'Oisien. Pratiquants, bénévoles, salariés, membres de bureaux, nous sommes tous invités à travailler ensemble pour développer notre art martial.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Les projets ambitieux portés par Maître Wilfried nécessitent le soutien et l'engagement de chacun d'entre nous. Communiquons, collaborons et faisons bouger les lignes pour que le Taekwondo du Val d'Oise rayonne davantage !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Merci à toutes celles et ceux qui soutiennent cette initiative. Ensemble, nous pouvons écrire une nouvelle histoire pour notre discipline. ",
          },
          {
            text: "Vive le Taekwondo !",
            isBold: true,
          },
        ],
      },
    ],
  },
  {
    id: "label-3-etoiles",
    title: "3 ÉTOILES POUR LE MUDO CLUB",
    description:
      "Le MuDo Club a été reconnu pour ses efforts en matière de qualité et de service, recevant le label 3 étoiles.",
    date: "2023-06-23T00:00:00Z",
    imageBanner: label3etoiles,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Nous sommes fiers d'annoncer que le MuDo Club a obtenu le label 3 étoiles de la Fédération Française de Taekwondo et des Disciplines Associées (FFTDA). Cette distinction témoigne de notre engagement constant envers la qualité de notre enseignement et de nos services.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "La labellisation qualité est un processus rigoureux organisé tous les 2 ans par la FFTDA. Ce label représente non seulement une reconnaissance de la fédération mais constitue également un repère qualité important pour les mairies et le public.",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Le label 3 étoiles reconnaît notamment :" }],
        items: [
          "La qualité de l'enseignement",
          "Le professionnalisme de l'encadrement",
          "La diversité des activités proposées",
          "Les infrastructures et équipements",
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Note : ", isBold: true },
          {
            text: "Pour plus d'informations sur le processus de labellisation et ses critères, nous vous invitons à consulter le site officiel de la FFTDA.",
          },
        ],
      },
    ],
  },
  {
    id: "preparation-mentale-avant-une-competition",
    title: "La préparation mentale en Taekwondo pour les compétitions",
    description:
      "Découvrez les techniques de préparation mentale utilisées par les champions pour rester concentré et performant lors des compétitions de Taekwondo.",
    date: "2024-12-26T00:00:00Z",
    imageBanner: preparationMentale,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "La préparation mentale est une composante essentielle de la réussite en compétition de Taekwondo. Au-delà de la technique et de la condition physique, c'est souvent l'état d'esprit qui fait la différence entre la victoire et la défaite. Les champions ne se distinguent pas uniquement par leurs capacités physiques, mais aussi par leur force mentale et leur capacité à gérer la pression.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "La visualisation est l'une des techniques les plus puissantes utilisées par les athlètes de haut niveau. Elle consiste à se représenter mentalement les mouvements, les situations de combat et la victoire. Cette pratique permet de renforcer les automatismes, d'améliorer la confiance en soi et de réduire l'anxiété avant les compétitions.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Conseil d'expert : ", isBold: true },
          {
            text: "Il est recommandé de commencer la préparation mentale plusieurs semaines avant une compétition importante. La régularité est la clé : consacrez quelques minutes chaque jour à des exercices de respiration, de méditation et de visualisation pour obtenir les meilleurs résultats.",
          },
        ],
      },
    ],
  },
  {
    id: "les-bienfaits-du-taekwondo-pour-les-enfants",
    title: "Les bienfaits du Taekwondo pour les enfants",
    description:
      "Le Taekwondo n'est pas seulement un sport. Il aide également au développement physique, mental et émotionnel des plus jeunes.",
    date: "2024-12-26T00:00:00Z",
    imageBanner: bienfaitsTaekwondo,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le Taekwondo représente bien plus qu'une simple activité sportive pour les enfants. Cette discipline millénaire constitue un véritable outil pédagogique qui contribue au développement global de l'enfant. Sur le plan physique, il améliore la coordination, la souplesse et développe harmonieusement la musculature, tout en respectant la croissance naturelle.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Au-delà des aspects physiques, le Taekwondo inculque des valeurs essentielles comme le respect, la persévérance et le contrôle de soi. Les enfants apprennent à se fixer des objectifs, à travailler dur pour les atteindre et à gérer leurs émotions. La progression par ceintures offre des repères clairs et valorisants qui renforcent leur estime de soi.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Témoignage de parent : ", isBold: true },
          {
            text: "\"Depuis que ma fille pratique le Taekwondo, j'ai observé des changements remarquables dans son comportement. Elle est plus disciplinée, plus confiante et a développé un véritable esprit d'entraide avec ses camarades. C'est une transformation qui va bien au-delà du sport.\"",
          },
        ],
      },
    ],
  },
  {
    id: "les-poomsae-comprendre-et-maitriser-les-formes-traditionnelles",
    title: "Les poomsae : comprendre et maîtriser les formes traditionnelles",
    description:
      "Plongez dans l'histoire et la signification des poomsae, ces enchaînements techniques qui constituent le cœur de l'apprentissage du Taekwondo.",
    date: "2024-12-26T00:00:00Z",
    imageBanner: poomsaeFormeTradi,
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Les poomsae, véritables trésors du Taekwondo, sont des enchaînements codifiés de techniques qui racontent l'histoire et transmettent l'essence de cet art martial. Chaque poomsae est une chorégraphie précise qui simule un combat contre des adversaires imaginaires, incorporant des techniques de blocage, de frappe et de déplacement dans un ordre spécifique.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "La pratique régulière des poomsae développe non seulement la technique, mais aussi la concentration et la précision. Chaque mouvement doit être exécuté avec la bonne respiration, le bon rythme et la bonne intention. Cette discipline exigeante permet de perfectionner sa technique tout en développant une compréhension plus profonde des principes du Taekwondo.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Note historique : ", isBold: true },
          {
            text: "Les poomsae modernes ont été standardisés par la Kukkiwon dans les années 1960 pour préserver et transmettre les techniques traditionnelles du Taekwondo. Chaque forme possède sa propre signification philosophique, souvent liée à des éléments de la culture coréenne.",
          },
        ],
      },
    ],
  },
];
